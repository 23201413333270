@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use 'styles/typography';
@use 'styles/spacing';

.placeholderCard {
  display: flex;
  flex-direction: row;
  row-gap: spacing.$spacing-12;
  column-gap: spacing.$spacing-16;
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  padding-top: spacing.$spacing-12;
  padding-bottom: spacing.$spacing-12;
  align-items: center;
  margin-left: spacing.$spacing-8;

  @for $i from 2 through 10 {
    :nth-child(#{$i}) > & {
      animation-delay: ($i - 1) * 0.3s;
    }
  }

  &::after {
    background-color: $yds-color-background-interactive;
    content: '';
    display: block;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.999;
  }
  50% {
    opacity: 0.8;
  }
}

.checkBox {
  display: flex;
  appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  background-color: $yds-color-background-interactive;
  border-radius: $yds-border-radius-medium;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  height: 0.5rem;
  width: 8rem;
  background-color: $yds-color-background-interactive;
}

.description {
  margin-top: 0.45rem;
  height: 0.5rem;
  width: 12rem;
  background-color: $yds-color-background-interactive;
}
