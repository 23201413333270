@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing';
@use 'styles/zIndex';
@use 'styles/breakpoints';

.root {
  background-color: $yds-color-black;
  color: $yds-color-text-default;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: all 0.2s;
  padding-top: spacing.$spacing-32;
  padding-bottom: spacing.$spacing-32;
  @include zIndex.player-level;

  &[data-state='open'] {
    animation: slideIn 0.2s ease-out;
  }
  &[data-state='closed'] {
    animation: slideOut 0.2s ease-in;
  }
}

.minifyButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: spacing.$spacing-48;
  right: spacing.$spacing-40;
  width: 100%;

  @include breakpoints.media(mobile-landscape) {
    top: spacing.$spacing-8;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-16;
  margin: 0 5%;
  align-items: center;
  justify-content: center;

  @include breakpoints.media(mobile-landscape) {
    gap: unset;
  }
}

.controlsWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: black;
  box-shadow: 0 0 16px $yds-color-black-opacity-60;
  padding: spacing.$spacing-20 spacing.$spacing-16 spacing.$spacing-32;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoints.media(mobile-landscape) {
    padding: spacing.$spacing-8 spacing.$spacing-32 spacing.$spacing-32;
  }
}

.menuControlGroup {
  display: flex;
  justify-content: center;
}

.moreDropdownButton {
  position: absolute;
  right: spacing.$spacing-10;
}
