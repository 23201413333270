@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/typography';
@use 'styles/spacing';

.cardContainer {
  margin-top: spacing.$spacing-16;
  margin-bottom: spacing.$spacing-16;
}

.nextInListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: spacing.$spacing-24;
  margin-bottom: spacing.$spacing-24;
}

.headerTexts {
  display: flex;
  flex-direction: column;
}

.subTitle {
  margin: 0;
}

.recommendationsInfoText {
  @include typography.default-xs;
  color: $yds-color-text-medium-emphasis;
}

.toggleWrapper {
  display: flex;
  align-self: flex-start;
  margin-left: spacing.$spacing-16;
}
