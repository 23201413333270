@use '@yleisradio/yds-core/lib/tokens/build/scss/typography' as *;
@use 'sass:math';

@function percentage-to-ratio($percentage) {
  @if not math.compatible($percentage, 1%) {
    @error 'Expected $percentage to be a percentage unit.';
  }
  @return math.div($percentage, 100%);
}

@function px-to-rem($px-value) {
  @if not math.compatible($px-value, 1px) {
    @error 'Expected $px-value to be a px unit.';
  }
  @return math.div($px-value, 16px) * 1rem;
}

/*
font: inherit is used to inherit the font-family from the body element
for elements that have different fonts set in browser stylesheets (such as <button>)
*/

@mixin _style(
  $font-size,
  $font-stretch,
  $font-style,
  $font-weight,
  $letter-spacing,
  $line-height,
  $text-decoration,
  $text-transform
) {
  font: inherit;
  font-size: px-to-rem($font-size);
  font-stretch: $font-stretch;
  font-style: $font-style;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  line-height: percentage-to-ratio($line-height);
  text-decoration: $text-decoration;
  text-transform: $text-transform;
}

@mixin default-7xl-bold {
  @include _style(
    $yds-typography-default-7xl-bold-font-size,
    $yds-typography-default-7xl-bold-font-stretch,
    $yds-typography-default-7xl-bold-font-style,
    $yds-typography-default-7xl-bold-font-weight,
    $yds-typography-default-7xl-bold-letter-spacing,
    $yds-typography-default-7xl-bold-line-height,
    $yds-typography-default-7xl-bold-text-decoration,
    $yds-typography-default-7xl-bold-text-case
  );
}

@mixin default-7xl-heavy {
  @include _style(
    $yds-typography-default-7xl-heavy-font-size,
    $yds-typography-default-7xl-heavy-font-stretch,
    $yds-typography-default-7xl-heavy-font-style,
    $yds-typography-default-7xl-heavy-font-weight,
    $yds-typography-default-7xl-heavy-letter-spacing,
    $yds-typography-default-7xl-heavy-line-height,
    $yds-typography-default-7xl-heavy-text-decoration,
    $yds-typography-default-7xl-heavy-text-case
  );
}

@mixin default-6xl-bold {
  @include _style(
    $yds-typography-default-6xl-bold-font-size,
    $yds-typography-default-6xl-bold-font-stretch,
    $yds-typography-default-6xl-bold-font-style,
    $yds-typography-default-6xl-bold-font-weight,
    $yds-typography-default-6xl-bold-letter-spacing,
    $yds-typography-default-6xl-bold-line-height,
    $yds-typography-default-6xl-bold-text-decoration,
    $yds-typography-default-6xl-bold-text-case
  );
}

@mixin default-6xl-heavy {
  @include _style(
    $yds-typography-default-6xl-heavy-font-size,
    $yds-typography-default-6xl-heavy-font-stretch,
    $yds-typography-default-6xl-heavy-font-style,
    $yds-typography-default-6xl-heavy-font-weight,
    $yds-typography-default-6xl-heavy-letter-spacing,
    $yds-typography-default-6xl-heavy-line-height,
    $yds-typography-default-6xl-heavy-text-decoration,
    $yds-typography-default-6xl-heavy-text-case
  );
}

@mixin default-5xl-bold {
  @include _style(
    $yds-typography-default-5xl-bold-font-size,
    $yds-typography-default-5xl-bold-font-stretch,
    $yds-typography-default-5xl-bold-font-style,
    $yds-typography-default-5xl-bold-font-weight,
    $yds-typography-default-5xl-bold-letter-spacing,
    $yds-typography-default-5xl-bold-line-height,
    $yds-typography-default-5xl-bold-text-decoration,
    $yds-typography-default-5xl-bold-text-case
  );
}

@mixin default-5xl-heavy {
  @include _style(
    $yds-typography-default-5xl-heavy-font-size,
    $yds-typography-default-5xl-heavy-font-stretch,
    $yds-typography-default-5xl-heavy-font-style,
    $yds-typography-default-5xl-heavy-font-weight,
    $yds-typography-default-5xl-heavy-letter-spacing,
    $yds-typography-default-5xl-heavy-line-height,
    $yds-typography-default-5xl-heavy-text-decoration,
    $yds-typography-default-5xl-heavy-text-case
  );
}

@mixin default-4xl-bold {
  @include _style(
    $yds-typography-default-4xl-bold-font-size,
    $yds-typography-default-4xl-bold-font-stretch,
    $yds-typography-default-4xl-bold-font-style,
    $yds-typography-default-4xl-bold-font-weight,
    $yds-typography-default-4xl-bold-letter-spacing,
    $yds-typography-default-4xl-bold-line-height,
    $yds-typography-default-4xl-bold-text-decoration,
    $yds-typography-default-4xl-bold-text-case
  );
}

@mixin default-4xl-heavy {
  @include _style(
    $yds-typography-default-4xl-heavy-font-size,
    $yds-typography-default-4xl-heavy-font-stretch,
    $yds-typography-default-4xl-heavy-font-style,
    $yds-typography-default-4xl-heavy-font-weight,
    $yds-typography-default-4xl-heavy-letter-spacing,
    $yds-typography-default-4xl-heavy-line-height,
    $yds-typography-default-4xl-heavy-text-decoration,
    $yds-typography-default-4xl-heavy-text-case
  );
}

@mixin default-3xl-bold {
  @include _style(
    $yds-typography-default-3xl-bold-font-size,
    $yds-typography-default-3xl-bold-font-stretch,
    $yds-typography-default-3xl-bold-font-style,
    $yds-typography-default-3xl-bold-font-weight,
    $yds-typography-default-3xl-bold-letter-spacing,
    $yds-typography-default-3xl-bold-line-height,
    $yds-typography-default-3xl-bold-text-decoration,
    $yds-typography-default-3xl-bold-text-case
  );
}

@mixin default-3xl-heavy {
  @include _style(
    $yds-typography-default-3xl-heavy-font-size,
    $yds-typography-default-3xl-heavy-font-stretch,
    $yds-typography-default-3xl-heavy-font-style,
    $yds-typography-default-3xl-heavy-font-weight,
    $yds-typography-default-3xl-heavy-letter-spacing,
    $yds-typography-default-3xl-heavy-line-height,
    $yds-typography-default-3xl-heavy-text-decoration,
    $yds-typography-default-3xl-heavy-text-case
  );
}

@mixin default-2xl-bold {
  @include _style(
    $yds-typography-default-2xl-bold-font-size,
    $yds-typography-default-2xl-bold-font-stretch,
    $yds-typography-default-2xl-bold-font-style,
    $yds-typography-default-2xl-bold-font-weight,
    $yds-typography-default-2xl-bold-letter-spacing,
    $yds-typography-default-2xl-bold-line-height,
    $yds-typography-default-2xl-bold-text-decoration,
    $yds-typography-default-2xl-bold-text-case
  );
}

@mixin default-2xl-bold {
  @include _style(
    $yds-typography-default-2xl-bold-font-size,
    $yds-typography-default-2xl-bold-font-stretch,
    $yds-typography-default-2xl-bold-font-style,
    $yds-typography-default-2xl-bold-font-weight,
    $yds-typography-default-2xl-bold-letter-spacing,
    $yds-typography-default-2xl-bold-line-height,
    $yds-typography-default-2xl-bold-text-decoration,
    $yds-typography-default-2xl-bold-text-case
  );
}

@mixin default-2xl-heavy {
  @include _style(
    $yds-typography-default-2xl-heavy-font-size,
    $yds-typography-default-2xl-heavy-font-stretch,
    $yds-typography-default-2xl-heavy-font-style,
    $yds-typography-default-2xl-heavy-font-weight,
    $yds-typography-default-2xl-heavy-letter-spacing,
    $yds-typography-default-2xl-heavy-line-height,
    $yds-typography-default-2xl-heavy-text-decoration,
    $yds-typography-default-2xl-heavy-text-case
  );
}

@mixin default-xl {
  @include _style(
    $yds-typography-default-xl-font-size,
    $yds-typography-default-xl-font-stretch,
    $yds-typography-default-xl-font-style,
    $yds-typography-default-xl-font-weight,
    $yds-typography-default-xl-letter-spacing,
    $yds-typography-default-xl-line-height,
    $yds-typography-default-xl-text-decoration,
    $yds-typography-default-xl-text-case
  );
}

@mixin default-xl-italic {
  @include _style(
    $yds-typography-default-xl-italic-font-size,
    $yds-typography-default-xl-italic-font-stretch,
    $yds-typography-default-xl-italic-font-style,
    $yds-typography-default-xl-italic-font-weight,
    $yds-typography-default-xl-italic-letter-spacing,
    $yds-typography-default-xl-italic-line-height,
    $yds-typography-default-xl-italic-text-decoration,
    $yds-typography-default-xl-italic-text-case
  );
}

@mixin default-xl-bold {
  @include _style(
    $yds-typography-default-xl-bold-font-size,
    $yds-typography-default-xl-bold-font-stretch,
    $yds-typography-default-xl-bold-font-style,
    $yds-typography-default-xl-bold-font-weight,
    $yds-typography-default-xl-bold-letter-spacing,
    $yds-typography-default-xl-bold-line-height,
    $yds-typography-default-xl-bold-text-decoration,
    $yds-typography-default-xl-bold-text-case
  );
}

@mixin default-xl-heavy {
  @include _style(
    $yds-typography-default-xl-heavy-font-size,
    $yds-typography-default-xl-heavy-font-stretch,
    $yds-typography-default-xl-heavy-font-style,
    $yds-typography-default-xl-heavy-font-weight,
    $yds-typography-default-xl-heavy-letter-spacing,
    $yds-typography-default-xl-heavy-line-height,
    $yds-typography-default-xl-heavy-text-decoration,
    $yds-typography-default-xl-heavy-text-case
  );
}

@mixin default-l {
  @include _style(
    $yds-typography-default-l-font-size,
    $yds-typography-default-l-font-stretch,
    $yds-typography-default-l-font-style,
    $yds-typography-default-l-font-weight,
    $yds-typography-default-l-letter-spacing,
    $yds-typography-default-l-line-height,
    $yds-typography-default-l-text-decoration,
    $yds-typography-default-l-text-case
  );
}

@mixin default-l-italic {
  @include _style(
    $yds-typography-default-l-italic-font-size,
    $yds-typography-default-l-italic-font-stretch,
    $yds-typography-default-l-italic-font-style,
    $yds-typography-default-l-italic-font-weight,
    $yds-typography-default-l-italic-letter-spacing,
    $yds-typography-default-l-italic-line-height,
    $yds-typography-default-l-italic-text-decoration,
    $yds-typography-default-l-italic-text-case
  );
}

@mixin default-l-bold {
  @include _style(
    $yds-typography-default-l-bold-font-size,
    $yds-typography-default-l-bold-font-stretch,
    $yds-typography-default-l-bold-font-style,
    $yds-typography-default-l-bold-font-weight,
    $yds-typography-default-l-bold-letter-spacing,
    $yds-typography-default-l-bold-line-height,
    $yds-typography-default-l-bold-text-decoration,
    $yds-typography-default-l-bold-text-case
  );
}

@mixin default-l-heavy {
  @include _style(
    $yds-typography-default-l-heavy-font-size,
    $yds-typography-default-l-heavy-font-stretch,
    $yds-typography-default-l-heavy-font-style,
    $yds-typography-default-l-heavy-font-weight,
    $yds-typography-default-l-heavy-letter-spacing,
    $yds-typography-default-l-heavy-line-height,
    $yds-typography-default-l-heavy-text-decoration,
    $yds-typography-default-l-heavy-text-case
  );
}

@mixin default-m {
  @include _style(
    $yds-typography-default-m-font-size,
    $yds-typography-default-m-font-stretch,
    $yds-typography-default-m-font-style,
    $yds-typography-default-m-font-weight,
    $yds-typography-default-m-letter-spacing,
    $yds-typography-default-m-line-height,
    $yds-typography-default-m-text-decoration,
    $yds-typography-default-m-text-case
  );
}

@mixin default-m-ac {
  @include _style(
    $yds-typography-default-m-ac-font-size,
    $yds-typography-default-m-ac-font-stretch,
    $yds-typography-default-m-ac-font-style,
    $yds-typography-default-m-ac-font-weight,
    $yds-typography-default-m-ac-letter-spacing,
    $yds-typography-default-m-ac-line-height,
    $yds-typography-default-m-ac-text-decoration,
    $yds-typography-default-m-ac-text-case
  );
}

@mixin default-m-italic {
  @include _style(
    $yds-typography-default-m-italic-font-size,
    $yds-typography-default-m-italic-font-stretch,
    $yds-typography-default-m-italic-font-style,
    $yds-typography-default-m-italic-font-weight,
    $yds-typography-default-m-italic-letter-spacing,
    $yds-typography-default-m-italic-line-height,
    $yds-typography-default-m-italic-text-decoration,
    $yds-typography-default-m-italic-text-case
  );
}

@mixin default-m-medium {
  @include _style(
    $yds-typography-default-m-medium-font-size,
    $yds-typography-default-m-medium-font-stretch,
    $yds-typography-default-m-medium-font-style,
    $yds-typography-default-m-medium-font-weight,
    $yds-typography-default-m-medium-letter-spacing,
    $yds-typography-default-m-medium-line-height,
    $yds-typography-default-m-medium-text-decoration,
    $yds-typography-default-m-medium-text-case
  );
}

@mixin default-m-bold {
  @include _style(
    $yds-typography-default-m-bold-font-size,
    $yds-typography-default-m-bold-font-stretch,
    $yds-typography-default-m-bold-font-style,
    $yds-typography-default-m-bold-font-weight,
    $yds-typography-default-m-bold-letter-spacing,
    $yds-typography-default-m-bold-line-height,
    $yds-typography-default-m-bold-text-decoration,
    $yds-typography-default-m-bold-text-case
  );
}

@mixin default-m-bold-ac {
  @include _style(
    $yds-typography-default-m-bold-ac-font-size,
    $yds-typography-default-m-bold-ac-font-stretch,
    $yds-typography-default-m-bold-ac-font-style,
    $yds-typography-default-m-bold-ac-font-weight,
    $yds-typography-default-m-bold-ac-letter-spacing,
    $yds-typography-default-m-bold-ac-line-height,
    $yds-typography-default-m-bold-ac-text-decoration,
    $yds-typography-default-m-bold-ac-text-case
  );
}

@mixin default-s {
  @include _style(
    $yds-typography-default-s-font-size,
    $yds-typography-default-s-font-stretch,
    $yds-typography-default-s-font-style,
    $yds-typography-default-s-font-weight,
    $yds-typography-default-s-letter-spacing,
    $yds-typography-default-s-line-height,
    $yds-typography-default-s-text-decoration,
    $yds-typography-default-s-text-case
  );
}

@mixin default-s-ac {
  @include _style(
    $yds-typography-default-s-ac-font-size,
    $yds-typography-default-s-ac-font-stretch,
    $yds-typography-default-s-ac-font-style,
    $yds-typography-default-s-ac-font-weight,
    $yds-typography-default-s-ac-letter-spacing,
    $yds-typography-default-s-ac-line-height,
    $yds-typography-default-s-ac-text-decoration,
    $yds-typography-default-s-ac-text-case
  );
}

@mixin default-s-italic {
  @include _style(
    $yds-typography-default-s-italic-font-size,
    $yds-typography-default-s-italic-font-stretch,
    $yds-typography-default-s-italic-font-style,
    $yds-typography-default-s-italic-font-weight,
    $yds-typography-default-s-italic-letter-spacing,
    $yds-typography-default-s-italic-line-height,
    $yds-typography-default-s-italic-text-decoration,
    $yds-typography-default-s-italic-text-case
  );
}

@mixin default-s-bold {
  @include _style(
    $yds-typography-default-s-bold-font-size,
    $yds-typography-default-s-bold-font-stretch,
    $yds-typography-default-s-bold-font-style,
    $yds-typography-default-s-bold-font-weight,
    $yds-typography-default-s-bold-letter-spacing,
    $yds-typography-default-s-bold-line-height,
    $yds-typography-default-s-bold-text-decoration,
    $yds-typography-default-s-bold-text-case
  );
}

@mixin default-s-bold-ac {
  @include _style(
    $yds-typography-default-s-bold-ac-font-size,
    $yds-typography-default-s-bold-ac-font-stretch,
    $yds-typography-default-s-bold-ac-font-style,
    $yds-typography-default-s-bold-ac-font-weight,
    $yds-typography-default-s-bold-ac-letter-spacing,
    $yds-typography-default-s-bold-ac-line-height,
    $yds-typography-default-s-bold-ac-text-decoration,
    $yds-typography-default-s-bold-ac-text-case
  );
}

@mixin default-xs {
  @include _style(
    $yds-typography-default-xs-font-size,
    $yds-typography-default-xs-font-stretch,
    $yds-typography-default-xs-font-style,
    $yds-typography-default-xs-font-weight,
    $yds-typography-default-xs-letter-spacing,
    $yds-typography-default-xs-line-height,
    $yds-typography-default-xs-text-decoration,
    $yds-typography-default-xs-text-case
  );
}

@mixin default-xs-ac {
  @include _style(
    $yds-typography-default-xs-ac-font-size,
    $yds-typography-default-xs-ac-font-stretch,
    $yds-typography-default-xs-ac-font-style,
    $yds-typography-default-xs-ac-font-weight,
    $yds-typography-default-xs-ac-letter-spacing,
    $yds-typography-default-xs-ac-line-height,
    $yds-typography-default-xs-ac-text-decoration,
    $yds-typography-default-xs-ac-text-case
  );
}

@mixin default-xs-italic {
  @include _style(
    $yds-typography-default-xs-italic-font-size,
    $yds-typography-default-xs-italic-font-stretch,
    $yds-typography-default-xs-italic-font-style,
    $yds-typography-default-xs-italic-font-weight,
    $yds-typography-default-xs-italic-letter-spacing,
    $yds-typography-default-xs-italic-line-height,
    $yds-typography-default-xs-italic-text-decoration,
    $yds-typography-default-xs-italic-text-case
  );
}

@mixin default-xs-bold {
  @include _style(
    $yds-typography-default-xs-bold-font-size,
    $yds-typography-default-xs-bold-font-stretch,
    $yds-typography-default-xs-bold-font-style,
    $yds-typography-default-xs-bold-font-weight,
    $yds-typography-default-xs-bold-letter-spacing,
    $yds-typography-default-xs-bold-line-height,
    $yds-typography-default-xs-bold-text-decoration,
    $yds-typography-default-xs-bold-text-case
  );
}

@mixin default-xs-bold-ac {
  @include _style(
    $yds-typography-default-xs-bold-ac-font-size,
    $yds-typography-default-xs-bold-ac-font-stretch,
    $yds-typography-default-xs-bold-ac-font-style,
    $yds-typography-default-xs-bold-ac-font-weight,
    $yds-typography-default-xs-bold-ac-letter-spacing,
    $yds-typography-default-xs-bold-ac-line-height,
    $yds-typography-default-xs-bold-ac-text-decoration,
    $yds-typography-default-xs-bold-ac-text-case
  );
}
