@use 'styles/typography';
@use 'styles/spacing';
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;

.container {
  margin-right: spacing.$spacing-20;
  margin-left: spacing.$spacing-20;
}

.cardContainer {
  margin-top: spacing.$spacing-16;
  margin-bottom: spacing.$spacing-16;
}

.emptyPlayQueueContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  @include typography.default-xl-bold;
  margin: 0;
  margin-top: 1.25px;
}

.subTitle {
  margin: 0;
  margin-top: spacing.$spacing-24;
  margin-bottom: spacing.$spacing-16;
}

.playQueueSubTitle {
  margin-top: spacing.$spacing-8;
  margin-bottom: 0;
}

.loginToUseText {
  @include typography.default-m-bold;
}

.queueHeaderRow {
  display: flex;
  justify-content: space-between;
}

.emptyQueueButtonWrapper {
  display: flex;
  align-self: center;
  padding-top: spacing.$spacing-12;
  padding-right: 2px;
  color: $yds-color-text-input-placeholder;
}
