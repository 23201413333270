@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.root {
  display: flex;
  align-items: center;

  @include breakpoints.media(tablet-landscape) {
    padding: 0;
  }
}

.link {
  display: flex;
  text-decoration: none;
  gap: spacing.$spacing-8;
}

.noPointerEvents {
  pointer-events: none;
}

.image {
  position: relative;
  flex-shrink: 0;
  width: 3.75rem;
}

.title {
  margin: 0;
  @include typography.default-s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.description {
  margin: 0;
  @include typography.default-xs;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
