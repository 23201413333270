/*
Root level z-indexes.

Example:
@use "styles/zIndex";
@include zIndex.menu-level;

*/

@mixin sticky-notification-level {
  z-index: 93;
}

@mixin areena-menu-level {
  z-index: 94;
}

@mixin share-dialog-level {
  z-index: 96;
}

@mixin message-to-studio-dialog-level {
  z-index: 96;
}

@mixin player-level {
  z-index: 97;
}

@mixin audio-player-expandable-button-level {
  z-index: 98;
}

@mixin chat-button-level {
  z-index: 98;
}

@mixin skip-link-level {
  z-index: 99;
}
