@use 'styles/breakpoints';
@use 'styles/zIndex';
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.root {
  color: white;
  background-color: transparent;
  border: unset;
  cursor: pointer;
  padding: unset;
  height: 2.75rem;
  width: 2.75rem;
  flex-shrink: 0;
  margin: 1rem 0;

  @include breakpoints.media(tablet-landscape) {
    margin: 0;
  }

  &:hover:enabled {
    color: $yds-color-gray-95;
    background: white;
    border-radius: 50%;
  }

  &:active {
    border-radius: 50%;
    background: $yds-color-gray-90;
    color: white;
  }

  &.large {
    height: 3.125rem;
    width: 3.125rem;

    @include breakpoints.media(mobile-landscape) {
      height: 2.75rem;
      width: 2.75rem;
    }
  }

  &.disabled {
    color: $yds-color-white-opacity-30;
    cursor: default;
  }

  &.elevated {
    @include zIndex.audio-player-expandable-button-level;
  }
}

.play,
.pause {
  width: 3.5rem;
  height: 3.5rem;

  @include breakpoints.media(desktop-small) {
    width: 3.125rem;
    height: 3.125rem;
  }

  &.large {
    width: 5rem;
    height: 5rem;

    @include breakpoints.media(mobile-landscape) {
      width: 3.125rem;
      height: 3.125rem;
    }
  }
}
.previous,
.next {
  padding: 0.625rem;
}
