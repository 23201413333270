@use 'styles/breakpoints';
@use 'styles/spacing';
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.controlGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
