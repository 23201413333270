@use 'styles/typography';
@use 'styles/spacing';
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;

.root {
  display: grid;
  align-items: center;
  background-color: rgba($yds-color-feedback-highlight, 0.24);
  grid-template: 'image text' / 70px minmax(0, 1fr);
}
a.root {
  text-decoration: none;
}

.image {
  width: 70px;
  height: 70px;
}

.textContent {
  margin-inline: spacing.$spacing-16;
}

.title {
  @include typography.default-m-bold;
  color: $yds-color-text-default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  @include typography.default-s;
  color: $yds-color-text-medium-emphasis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
