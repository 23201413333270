@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/shadow-dark' as *;
@use 'styles/spacing';
@use 'styles/typography';

.trigger {
  position: relative;
  appearance: none;
  background: transparent;
  border: none;

  color: $yds-color-action-primary;
  cursor: pointer;
  padding: 0;
  border-radius: $yds-border-radius-large;

  &:active {
    background-color: $yds-color-action-secondary-variant;
  }

  &:hover {
    background-color: $yds-color-background-interactive;
    border-radius: $yds-border-radius-large;
  }

  &[data-state='open'] {
    background: $yds-color-background-interactive;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $yds-border-radius-large;
    border-bottom-right-radius: $yds-border-radius-large;
  }
}

.triggerIcon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}

.content {
  animation: in forwards 0.2s;
  display: flex;
  flex-direction: column;
  background-color: $yds-color-background-interactive;
  border-top-left-radius: $yds-border-radius-large;
  border-top-right-radius: $yds-border-radius-large;
  border-bottom-left-radius: $yds-border-radius-large;
  border-bottom-right-radius: 0;
  color: $yds-color-action-primary;
  transform-origin: var(--radix-dropdown-menu-content-transform-origin);
  width: 18.75rem;
  z-index: 98;
  margin-bottom: -5px;
}

@keyframes in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
}

.label {
  @include typography.default-m-bold;
  margin: spacing.$spacing-16;
  margin-bottom: spacing.$spacing-8;
  padding-bottom: spacing.$spacing-16;
  border-bottom: 1px solid $yds-color-border-separator;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: spacing.$spacing-16;
}

.rate {
  @include typography.default-m;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: spacing.$spacing-8 spacing.$spacing-16;
  cursor: pointer;

  &:hover {
    background-color: $yds-color-action-secondary-variant;
    outline: none;
  }
}

.checkboxContainer {
  flex: none;
  margin-right: spacing.$spacing-12;
}

.checkbox {
  background-color: $yds-color-background;
  border-radius: 50%;
  border: 2px solid $yds-color-border;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checked {
  width: 0.75rem;
  background-color: $yds-color-border;
  height: 0.75rem;
  border-radius: 50%;
}
