@use 'styles/spacing';
@use 'styles/typography';

.text {
  margin-left: spacing.$spacing-8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: spacing.$spacing-8;
}

.title {
  margin: 0;
  @include typography.default-s-bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  margin: 0;
  @include typography.default-xs;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
