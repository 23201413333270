@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.pointerTimeWrapper {
  position: relative;
  top: 0.625rem;
  margin: 0 1rem;
  display: none;

  @include breakpoints.media(tablet-landscape) {
    display: block;
  }
}

.pointerTimeWrapperExpanded {
  margin: 0;
  display: block;
  top: 6px;
}

.pointerTime {
  position: absolute;
  display: flex;
  top: -6px;
  color: $yds-color-white;
  @include typography.default-xs-bold;
  text-align: center;
}

.pointerTimeExpanded {
  top: -28px;
}

.pointerTimeWrapper {
  position: relative;
}
