@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/breakpoints';

.bar {
  user-select: none;
  touch-action: none;
  background: $yds-color-red-50;
  height: 3px;
  border-radius: 10px;

  @include breakpoints.media(tablet-landscape) {
    margin: 1.55rem 1rem;
  }
}
