@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing';
@use 'styles/breakpoints';
@use 'styles/typography';

.icon {
  cursor: pointer;
}

.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  font: inherit;
  cursor: pointer;
  margin: 0;
  max-width: 100%;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  color: $yds-color-text-default;
  gap: spacing.$spacing-10;

  box-shadow: inset 0 0 0 2px $yds-color-action-primary;

  &:focus {
    box-shadow:
      inset 0 0 0 2px $yds-color-action-primary,
      0 0 0 2px $yds-color-background,
      0 0 0 4px $yds-color-border-secondary-focus;
    outline: none;
  }

  &:focus:not(:focus-visible) {
    box-shadow: inset 0 0 0 2px $yds-color-action-primary;
  }

  &:focus-visible {
    box-shadow:
      inset 0 0 0 2px $yds-color-action-primary,
      0 0 0 2px $yds-color-background,
      0 0 0 4px $yds-color-border-secondary-focus;
    outline: none;
  }

  &:hover {
    background-color: $yds-color-action-secondary-variant;
  }

  &:active {
    background-color: $yds-color-action-secondary-variant;
  }

  @media only screen and (min-width: 800px) and (max-width: 1020px) {
    box-shadow: none;
    border-radius: 0;

    &:focus {
      box-shadow: none;
      outline: solid;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.buttonText {
  display: block;

  @media only screen and (min-width: 800px) and (max-width: 1020px) {
    display: none;
  }
}

.extra-small {
  @include typography.default-xs-bold;
  padding-left: spacing.$spacing-16;
  padding-right: spacing.$spacing-16;
  padding-top: spacing.$spacing-8;
  padding-bottom: spacing.$spacing-8;

  @media only screen and (min-width: 800px) and (max-width: 1020px) {
    padding-left: spacing.$spacing-4;
    padding-right: spacing.$spacing-4;
  }

  .icon {
    height: 1rem;
    width: unset;

    @media only screen and (min-width: 800px) and (max-width: 1020px) {
      height: 2rem;
    }
  }
}

.small {
  @include typography.default-xs-bold;
  padding: spacing.$spacing-8 spacing.$spacing-16;

  .icon {
    width: 1.125rem;
    height: 1.125rem;
  }
}
