@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.backgroundImage {
  scale: 1.6;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $yds-color-black-opacity-90;
  overflow: hidden;
}
