@use 'styles/typography';
@use 'styles/spacing';
@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;

.container {
  display: flex;
  align-items: center;
  row-gap: spacing.$spacing-16;
  column-gap: spacing.$spacing-8;
}

.cardTextContentWrapper {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.cardTitle {
  @include typography.default-m-bold;
  margin: 0;
  padding: 0;
}

.cardDescription {
  @include typography.default-s;
  color: $yds-color-text-medium-emphasis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.queueCheckBox {
  appearance: none;
  cursor: inherit;
  height: 1.5rem;
  width: 1.5rem;
  border: 2px solid $yds-color-background-negative;
  background-color: transparent;
  border-radius: $yds-border-radius-medium;
  margin: 0;
}

.queueCheckBoxLabel {
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  padding: spacing.$spacing-10;
}

.queueCheckBox:checked {
  background-size: cover;
  padding: 2px;
}

.queueCheckBox[type='checkbox']:not(:disabled):checked {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" aria-hidden="true"><path fill="white" fill-rule="evenodd" d="M20.707 5.293a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L9 15.586 19.293 5.293a1 1 0 0 1 1.414 0" clip-rule="evenodd"/></svg>');
}

.handle {
  appearance: none;
  background: none;
  border: none;
  cursor: grab;
  font: inherit;
  display: flex;
  margin: 0;
  padding: spacing.$spacing-10;
  padding-right: 0;
  touch-action: none;
  margin-left: auto;
  color: $yds-color-background-negative;
}

.handleDragging {
  cursor: grabbing;
}

.handleIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.handlePlaceholder {
  box-sizing: content-box;
  width: 1.5rem;
  height: 1.5rem;
  padding: spacing.$spacing-10;
}
