@use 'styles/typography';
@use 'styles/breakpoints';
@use 'styles/spacing';
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.card {
  padding: spacing.$spacing-80 0 spacing.$spacing-24 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  @include breakpoints.media(mobile-landscape) {
    flex-direction: row;
    margin: 0;
    padding: 0;
    gap: spacing.$spacing-16;
    margin-top: spacing.$spacing-32;
  }

  @include breakpoints.media(tablet) {
    flex-direction: column;
    padding: 0;
    max-width: 21rem;
  }
}

.imageContainer {
  flex: 1;

  @include breakpoints.orientation(portrait) {
    width: 12rem;
    margin: auto;
  }

  @include breakpoints.media(tablet) {
    margin: 0;
    width: unset;
  }
}

.imageWrapper {
  position: relative;
  padding-bottom: 100%;
}

.image {
  position: relative;
}

.textContainer {
  position: relative;
  flex: 2;
  display: flex;
  flex-direction: column;
}

.primaryText {
  @include typography.default-l-bold;
  margin: 1.5rem 0 0 0;
  text-align: left;
  -webkit-line-clamp: 1;
  @include breakpoints.media(tablet) {
    -webkit-line-clamp: 2;
  }
}

.secondaryText {
  margin: 0.5rem 0 1rem;
  @include typography.default-m;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
