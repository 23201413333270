@use 'styles/breakpoints';
@use 'styles/spacing';

.controls {
  display: flex;
  gap: spacing.$spacing-32;
}

.controlsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlGroup {
  flex: 2;
}

.controlGroup:not(:first-child):not(:last-child) {
  flex: 1;
}

.controlGroup:last-child {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  @include breakpoints.media(tablet-landscape) {
    gap: 1rem;
  }

  @include breakpoints.media(desktop-small) {
    gap: spacing.$spacing-40;
  }
}
