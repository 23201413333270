@use 'styles/spacing';
@use 'styles/breakpoints';
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;

.toggleQueuePrompt {
  display: flex;
  justify-content: center;
  gap: spacing.$spacing-24;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: $yds-color-background-interactive;
  padding: spacing.$spacing-24 2.625rem;
  width: 100%;
}
