@use 'sass:map';

/*
Example:
@use "styles/breakpoints";

.myStyle {
  @include breakpoints.media(tablet) {
    border: 1px solid yellow;
  }
}
*/

$breakpoints: (
  mobile-landscape: 480px,
  tablet: 640px,
  tablet-landscape: 800px,
  desktop-small: 1020px,
  desktop: 1400px,
  desktop-xl: 1920px,
);

@function map-get-breakpoint($key) {
  @if map.has-key($breakpoints, $key) {
    @return map.get($breakpoints, $key);
  } @else {
    @error "ERROR: breakpoint #{$key} doesn't exist in the breakpoints map";
  }
}

@mixin media($breakpoint) {
  $breakpoint-value: map-get-breakpoint($breakpoint);

  @media screen and (min-width: $breakpoint-value) {
    @content;
  }
}

@mixin orientation($orientation) {
  @media (orientation: $orientation) {
    @content;
  }
}
