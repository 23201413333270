@use 'styles/spacing';
@use 'styles/zIndex';
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.controls {
  display: flex;
  padding: 0 spacing.$spacing-16;
  justify-content: space-between;
  align-items: center;
}
