@use 'styles/typography';
@use 'styles/spacing';
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;

.switchRoot {
  width: 51px;
  height: 31px;
  background-color: $yds-color-background-control-disabled;
  border: none;
  border-radius: $yds-border-radius-full;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 100ms;
}

.switchRoot[data-state='checked'] {
  background-color: $yds-color-background-negative;
}

.switchThumb {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 27px;
  height: 27px;
  background-color: $yds-color-text-negative;
  border-radius: $yds-border-radius-full;
  transition: transform 100ms;
}

.switchThumb[data-state='checked'] {
  transform: translateX(20px);
}
