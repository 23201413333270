/*
Use without multiplying or dividing.

Source: https://www.figma.com/file/IktIO1bcWzkfRj0VtbTb3g/Areena-Web-VOD-MASTER?node-id=6%3A1180

Example:

@use "styles/spacing";
padding-right: spacing.$spacing-16;

*/

$spacing-2: 0.125rem;
$spacing-4: 0.25rem;
$spacing-6: 0.375rem;
$spacing-8: 0.5rem;
$spacing-10: 0.625rem;
$spacing-12: 0.75rem;
$spacing-16: 1rem;
$spacing-20: 1.25rem;
$spacing-24: 1.5rem;
$spacing-32: 2rem;
$spacing-40: 2.5rem;
$spacing-48: 3rem;
$spacing-56: 3.5rem;
$spacing-64: 4rem;
$spacing-72: 4.5rem;
$spacing-80: 5rem;
