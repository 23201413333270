@use 'styles/breakpoints';
@use 'styles/typography';
@use 'styles/spacing';

.root {
  margin-left: spacing.$spacing-16;
  margin-right: spacing.$spacing-16;
  margin-bottom: spacing.$spacing-16;
}

.heading {
  @include typography.default-xl-bold;
  margin-top: 0;
  margin-bottom: spacing.$spacing-24;

  @include breakpoints.media(tablet) {
    margin-bottom: spacing.$spacing-32;
  }

  @include breakpoints.media(desktop-small) {
    margin-bottom: spacing.$spacing-24;
  }
}

.songContainer {
  display: flex;
  flex-direction: row;
  gap: spacing.$spacing-8;
  margin-bottom: spacing.$spacing-10;
}

.time {
  @include typography.default-s-bold;
}

.description {
  @include typography.default-s;
}

.rightSide {
  display: flex;
  flex-direction: column;
}

.title {
  @include typography.default-s-bold;
}

.performer {
  @include typography.default-s;
}
