@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/audio-player-dimensions';
@use 'styles/zIndex';
@use 'styles/breakpoints';
@use 'styles/spacing';

.root {
  background-color: $yds-color-black;
  color: $yds-color-text-default;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: audio-player-dimensions.$height-mobile;
  animation: fadeIn 300ms ease-out;
  box-shadow: 0 0 16px $yds-color-black-opacity-60;
  @include zIndex.player-level;

  &[hidden] {
    animation: fadeOut 300ms ease-in;
  }

  @include breakpoints.media(tablet-landscape) {
    height: audio-player-dimensions.$height-tablet-landscape;
    padding: 0 5%;
  }
}

.expandButton {
  background-color: transparent;
  border: solid transparent 2px;
  color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.withClosePlayerButton {
  left: 4.75rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.playerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
