@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/audio-player-dimensions';
@use 'styles/zIndex';
@use 'styles/breakpoints';

.root {
  min-height: audio-player-dimensions.$height-mobile;

  @include breakpoints.media(tablet-landscape) {
    min-height: audio-player-dimensions.$height-tablet-landscape;
  }
}
