@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/breakpoints';

@mixin roundedBar {
  border-radius: 9999px;
}

@mixin visibleThumb {
  visibility: visible;
  height: 1rem;
  width: 1rem;
}

.barSliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  height: 8px;

  @include breakpoints.media(tablet-landscape) {
    height: 3px;
    margin: 0.4375rem 1rem;
    padding: 1.2rem 0;
  }

  &:focus-within {
    box-shadow: 0 0 0 2px $yds-color-gray-5;
  }
  &:hover,
  &:focus-within {
    @include breakpoints.media(tablet-landscape) {
      height: 6px;
    }
  }
}

.barSliderRootExpanded {
  margin: 0;
  padding: 0.2rem 0;

  @include breakpoints.orientation(portrait) {
    height: 8px;
  }
  @include breakpoints.orientation(landscape) {
    height: 3px;
  }
}

.barSliderTrack {
  background-color: $yds-color-white-opacity-40;
  position: relative;
  flex-grow: 1;
  height: inherit;
  cursor: pointer;

  @include breakpoints.media(tablet-landscape) {
    @include roundedBar;
  }
}

.barSliderRange {
  position: absolute;
  background-color: $yds-color-brand;
  height: 100%;

  @include breakpoints.media(tablet-landscape) {
    @include roundedBar;
  }
}

.barSliderTrackExpanded,
.barSliderRangeExpanded {
  &:focus-within,
  &:hover {
    height: 6px;

    .barSliderThumb {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}

.barSliderThumb {
  display: block;
  background-color: $yds-color-brand;
  border-radius: 50%;
  visibility: hidden;
  cursor: pointer;

  @include breakpoints.media(tablet-landscape) {
    @include visibleThumb;
  }

  &:focus-visible {
    outline: none;
  }
}

.barSliderThumbExpanded {
  @include visibleThumb;

  @include breakpoints.orientation(portrait) {
    height: 1.25rem;
    width: 1.25rem;
  }

  @include breakpoints.orientation(landscape) {
    width: 8px;
    height: 8px;
  }
}
