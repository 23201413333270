@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/zIndex';
@use 'styles/breakpoints';
@use 'styles/spacing';

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include breakpoints.media(tablet-landscape) {
    flex-wrap: nowrap;
  }
}

.progressBarContainer {
  width: 100%;
}

.progressBarContainerExpanded {
  order: 2;

  @include breakpoints.media(mobile-landscape) {
    order: 0;
    margin-top: 0;
    flex: 8;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.timeWrapper {
  display: none;
  min-width: 4rem;

  @include breakpoints.media(tablet-landscape) {
    display: flex;
    align-items: center;
  }
}

.timeWrapperExpanded {
  display: flex;
  align-items: center;
  margin-bottom: spacing.$spacing-16;
}
.timeWrapperLeft {
  justify-content: flex-start;
}
.timeWrapperRight {
  justify-content: flex-end;
}
