@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'sass:math';
@use 'styles/breakpoints';
@use 'styles/zIndex';

.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  background-color: $yds-color-background;
  @include zIndex.player-level;
}

.modalContent {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include zIndex.player-level;

  @media (orientation: landscape) {
    flex-direction: row;
  }

  &:focus {
    outline: none;
  }
}

.playerSection {
  position: relative;

  .modalContentWithOnlyPlayer & {
    flex-grow: 1;
  }

  @media (orientation: landscape) {
    flex-grow: 1;
    flex-basis: 50%;
  }
  @include breakpoints.media(tablet-landscape) {
    flex-basis: 66.6%;
  }
  @include breakpoints.media(desktop-small) {
    flex-basis: 75%;
  }
  @media (orientation: portrait) {
    padding-top: math.percentage(math.div(1, math.div(16, 9)));
  }
}

.playerInner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.chatSection {
  flex-grow: 1;
  position: relative;

  @media (orientation: landscape) {
    flex-basis: 50%;
  }
  @include breakpoints.media(tablet-landscape) {
    flex-basis: 33.3%;
  }
  @include breakpoints.media(desktop-small) {
    flex-basis: 25%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
