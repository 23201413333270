@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $yds-color-black-opacity-60;
  color: $yds-color-white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  pointer-events: auto;

  svg {
    fill: currentColor;
  }
}
